<template>
    <div class="">
        <div class="row align-items-center mx-0">

            <div class="col-12 col-sm-5 col-md-6 order-2 order-sm-1 px-0 d-sm-none d-lg-flex">
                <img src="@/assets/home-about-2.jpg"
                     class="img-fluid">
            </div>

            <div class="col-12 col-sm-6 col-md-6 order-2 order-sm-1 px-0 d-none d-sm-flex d-lg-none">
                <img src="@/assets/home-about-1.jpg"
                     class="img-fluid">
            </div>

            <div class="col-12 col-sm-6 ps-4 ps-md-5 py-5 order-sm-2">
                <h2 class="title-mb" v-html="$t('home.sales-points.heading')"></h2>
                <p v-html="$t('home.sales-points.body')"></p>

                <router-link class="icon-link icon-link-hover fw-bold"
                   to="/about">
                    {{ $t('home.sales-points.button') }}
                    <svg class="bi"
                         width="32"
                         height="32"
                         fill="blue">
                        <use xlink:href="@/assets/bootstrap-icons.svg#arrow-right" />
                    </svg>
                </router-link>

            </div>
            


            <!-- <div class="col-6 col-md-3">
                <img src="@/assets/test-2.jpg"
                     class="img-fluid">
            </div> -->



        </div>
    </div>
</template>

<script setup lang="ts">

</script>

<style scoped lang="scss">
@import "@/scss/abstracts/variables.scss";
@import "@/scss/abstracts/customBootstrap.scss";

.section-sales-points {
    padding: {
        top: $section-padding-top;
        bottom: $section-padding-bottom;
    }

    background-color: #ddd0c834;

}

.icon-link {
    color: $primary;
    text-decoration-color: $primary;
    text-underline-offset: 0.375rem;
}

.img-fluid {
    border-radius: 0;
}
</style>