import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import NotFoundView from "@/views/NotFoundView.vue";
import Collapse from "bootstrap/js/dist/collapse.js"

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/shop',
    children: [
      {
        path: '',
        name: 'shop',
        component: () => import('../views/shop/ProductsView.vue')
      },
      {
        path: "gift-card",
        name: 'giftcard',
        component: () => import('../views/GiftCardView.vue')
      },
      {
        path: "earrings/:productNameEng",
        name: 'earring',
        component: () => import('../views/shop/EarringView.vue')
      },
      {
        path: ":productNameEng",
        name: 'singleproductpage',
        component: () => import('../views/shop/SingleProductView.vue')
      }
    ]
  },

  // {
  //   path: '/team',
  //   name: 'team',
  //   component: () => import('../views/TeamView.vue')
  // },

  {
    path: '/custommade',
    name: 'custommade',
    component: () => import('../views/CustomMadeView.vue')
  },

  {
    path: '/sizingguide',
    name: 'sizingguide',
    component: () => import('../views/SizingGuideView.vue')
  },

  {
    path: '/garmentcare',
    name: 'garmentcare',
    component: () => import('../views/GarmentCareView.vue')
  },

  {
    path: '/deliveryreturns',
    name: 'deliveryreturns',
    component: () => import('../views/DeliveryReturnsView.vue')
  },

  {
    path: '/colorguide',
    name: 'colorguide',
    component: () => import('../views/ColorGuideView.vue')
  },

  {
    path: '/termsconditions',
    name: 'termsconditions',
    component: () => import('../views/TermsConditionsView.vue')
  },

  {
    path: '/privacypolicy',
    name: 'privacypolicy',
    component: () => import('../views/PrivacyPolicyView.vue')
  },
  
  {
    path: '/contact',
    name: 'contact',
    component: () => import('../views/ContactView.vue')
  },

  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: NotFoundView
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: "smooth",
        top: 120
      };
    }

    if (to.path == from.path && JSON.stringify(to.query) == JSON.stringify(from.query)) {
      return;
    }

    if (savedPosition) {
      return savedPosition;
    }

    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({top: 0, behavior: "smooth"})
      }, 100)
    });
  }
});
router.beforeEach(() => {
  const navbarElement = document.getElementById('navbarSupportedContent');
  if (navbarElement?.classList.contains('show')){
    Collapse.getOrCreateInstance(navbarElement, {
      toggle: false
    }).toggle();
  }
})

export default router
