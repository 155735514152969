import {api} from "@/api-client";
import cultureManager from "@/CultureManager";

export class Product {
    id: number;
    nameEst: string;
    nameEng: string;
    price?: number | null;
    finalPrice?: number | null;
    discountPercentage?: number | undefined;
    onDisplay: boolean;
    isFeatured: boolean;
    imageSrc: string;
    altImageSrc?: string | undefined;
    productCategoryId: number;

    constructor(id: number, nameEst: string, nameEng: string, price: number | null, finalPrice: number | null, onDisplay: boolean, isFeatured: boolean, imageSrc: string, altImageSrc: string | undefined, productCategoryId: number) {
        this.id = id;
        this.nameEst = nameEst;
        this.nameEng = nameEng;
        this.price = price;
        this.finalPrice = finalPrice;
        this.onDisplay = onDisplay;
        this.isFeatured = isFeatured;
        this.imageSrc = imageSrc;
        this.altImageSrc = altImageSrc;
        this.productCategoryId = productCategoryId;
    }
    
    getImageSource(): string {
        return require(`@/assets/${this.imageSrc}`);
    }
    
    getAltImageSource(): string | undefined {
        return this.altImageSrc ? require(`@/assets/${this.altImageSrc}`) : undefined;
    }

    localizedName(): string {
        return cultureManager.isEstonian ? this.nameEst : this.nameEng;
    }
    
    getPrice(): string | null {
        if (this.price == null && this.finalPrice == null) {
            return null;
        }
        
        if (this.isGiftCard()){
            return null;
        }
        
        if (this.finalPrice == null) {
            return this.price! + " €";
        }
        
        return this.finalPrice! + " €";
    }
    
    // BAD HACK!
    isGiftCard(): boolean {
        return this.id === 999;
    }
}

class ProductsProvider {
    private products: Product[] = [        
        
        // Earrings
        new Product(81, "Sayuri", "Sayuri", 28.00, 28.00, true, false, "product/earrings/sayuri/sayuri-1.jpg", "product/earrings/sayuri/sayuri-2.jpg",10),        
        new Product(87, "Hikari", "Hikari", 28.00, 28.00, true, false, "product/earrings/hikari/hikari-1.jpg", "product/earrings/hikari/hikari-2.jpg",10),
        new Product(88, "Momoka", "Momoka", 18.00, 18.00, true, false, "product/earrings/momoka/momoka-1.jpg", "product/earrings/momoka/momoka-2.jpg",10),
        new Product(82, "Honoka", "Honoka", 22.00, 22.00, true, false, "product/earrings/honoka/honoka-1.jpg", "product/earrings/honoka/honoka-2.jpg",10),
        new Product(86, "Kimiko", "Kimiko", 18.00, 18.00, true, false, "product/earrings/kimiko/kimiko-1.jpg", "product/earrings/kimiko/kimiko-4.jpg",10),
        new Product(83, "Nobuko", "Nobuko", 22.00, 22.00, true, false, "product/earrings/nobuko/nobuko-1.jpg", "product/earrings/nobuko/nobuko-2.jpg",10),
        new Product(85, "Tamiko", "Tamiko", 24.00, 24.00, true, false, "product/earrings/tamiko/tamiko-1.jpg", "product/earrings/tamiko/tamiko-2.jpg",10),
        new Product(80, "Miyu", "Miyu", 20.00, 20.00, true, false, "product/earrings/miyu/miyu-1.jpg", "product/earrings/miyu/miyu-2.jpg",10),
        new Product(84, "Hoshi", "Hoshi", 22.00, 22.00, true, true, "product/earrings/hoshi/hoshi-1.jpg", "product/earrings/hoshi/hoshi-2.jpg",10),

        // Dresses
        new Product(10, "Millie", "Millie", 175.00, 175.00, true, true, "product/dresses/millie/millie-1.jpg", "product/dresses/millie/millie-4.jpg",1),
        new Product(11, "Petra", "Petra", 150.00, 150.00, true, false, "product/dresses/petra/petra-1.jpg", "product/dresses/petra/petra-4.jpg",1),
        new Product(8, "Heidi", "Heidi", 180.00, 180.00, true, false, "product/dresses/heidi/heidi-3.jpg", "product/dresses/heidi/heidi-1.jpg",1),
        new Product(7, "Sara", "Sara", 195.00, 195.00, true, false, "product/dresses/sara/sara-1.jpg", "product/dresses/sara/sara-4.jpg",1), 

        new Product(4, "Elena", "Elena", 125.00, 125.00, true, false, "product/dresses/elena/elena-1.jpg", "product/dresses/elena/elena-4.jpg",1),
        new Product(6, "Naomi", "Naomi", 135.00, 135.00, true, false, "product/dresses/naomi/naomi-6.jpg", "product/dresses/naomi/naomi-1.jpg",1),
        new Product(1, "Nora", "Nora", 145.00, 145.00, true, false, "product/dresses/nora/nora-1.jpg", "product/dresses/nora/nora-5.jpg",1),
        new Product(9, "Eleanor", "Eleanor", 190.00, 190.00, true, false, "product/dresses/eleanor/eleanor-15.jpg", "product/dresses/eleanor/eleanor-12.jpg", 1),
        
        new Product(3, "Leanne", "Leanne", 180.00, 180.00, true, false, "product/dresses/leanne/leanne-2.jpg", "product/dresses/leanne/leanne-1.jpg",1),
        new Product(5, "Aurora", "Aurora", 135.00, 135.00, true, false, "product/dresses/aurora/test-1.jpg", "product/dresses/aurora/aurora-3.jpg",1),
        new Product(2, "Aurelia", "Aurelia", 140.00, 140.00, false, false, "product/dresses/aurelia/aurelia-1.jpg", "product/dresses/aurelia/aurelia-4.jpg",1),

        // Kimonos
        new Product(20, "Rue", "Rue", 195.00, 195.00, true, true, "product/kimonos/rue/rue-1.jpg", "product/kimonos/rue/rue-4.jpg",2),
        
        // Tops
        new Product(32, "Idalia", "Idalia", 95.00, 95.00, true, false, "product/tops/idalia/idalia-1.jpg", "product/tops/idalia/idalia-4.jpg",3),
        new Product(33, "Rosie", "Rosie", 85.00, 85.00, true, false, "product/tops/rosie/rosie-9.jpg", "product/tops/rosie/rosie-1.jpg",3),        
        new Product(31, "Noelle", "Noelle", 90.00, 90.00, true, false, "product/tops/noelle/noelle-9.jpg", "product/tops/noelle/noelle-1.jpg",3), 
        new Product(35, "Emma", "Emma", 85.00, 85.00, true, false, "product/tops/emma/emma-1.jpg", "product/tops/emma/emma-5.jpg",3),
        new Product(34, "Krysia", "Krysia", 115.00, 115.00, true, false, "product/tops/krysia/krysia-1.jpg", "product/tops/krysia/krysia-4.jpg",3),
        new Product(30, "Kaelene", "Kaelene", 105.00, 105.00, true, false, "product/tops/kaelene/kaelene-1.jpg", "product/tops/kaelene/kaelene-5.jpg",3),
        
        // Skirts
        new Product(40, "Layla", "Layla", 140.00, 140.00, true, false, "product/skirts/layla/layla-1.jpg", "product/skirts/layla/layla-3.jpg",4),
        new Product(41, "Emery", "Emery", 155.00, 155.00, true, false, "product/skirts/emery/emery-2.jpg", "product/skirts/emery/emery-3.jpg",4),
        
        // Pants
        new Product(50, "Hazel", "Hazel", 125.00, 125.00, true, false, "product/pants/hazel/hazel-1.jpg", "product/pants/hazel/hazel-3.jpg",5),
        
        // Jumpsuits
        new Product(60, "Sophia", "Sophia", 155.00, 155.00, true, false, "product/jumpsuits/sophia/sophia-1.jpg", "product/jumpsuits/sophia/sophia-4.jpg",6),
        
        // Others
        new Product(70, "Linased patsikummid (3 tk)", "Linen scrunchies (set of 3)", 20.00, 20.00, true, false, "product/accessories/scrunchie/scrunchie-1.jpg", "product/accessories/scrunchie/scrunchie-5.jpg",7),
        new Product(71, "Linane peapael", "Linen headband", 30.00, 30.00, true, false, "product/accessories/headband/headband-1.jpg", "product/accessories/headband/headband-2.jpg",7),
        new Product(72, "Linane sall", "Linen scarf", 65.00, 65.00, true, false, "product/accessories/scarf/scarf-1.jpg", "product/accessories/scarf/scarf-2.jpg",7),
        
        // Do not change Id of the gift card. Explicit routing is dependent on exact value 999
        new Product(999, "Kinkekaart", "Gift card", null, null, true, false, "product/accessories/gift-card.jpg", "product/accessories/gift-card.jpg",7)
    ];
    
    getAllProducts(): Product[] {
        return this.products;
    }
    
    getAllVisibleProducts(): Product[] {
        return this.products.filter(p => p.onDisplay);
    }

    getForCategory(categoryId: number): Product[] {
        return this.products.filter(p => p.productCategoryId === categoryId);
    }
    
    getProduct(productId: number): Product | undefined {
        return this.products.find(p => p.id === productId);
    }
    
    getProductByName(name: string): Product | undefined {
        return this.products.find(p => p.nameEng === name);
    }
    
    getFeaturedProducts(): Product[] {
        return this.products.filter(p => p.isFeatured);
    }
    
    getGiftCard(): Product {
        return this.products.find(p => p.isGiftCard())!;
    }
}

export const productsProvider = new ProductsProvider();
