<template>
    <div class="footer-wrapper">
        <footer class="container-fluid ">
            <div class="row justify-content-center">
                <div class="col-12">
                    <div class="row mb-3">
                        <div class="col-6 col-sm-3">
                            <ul class="list-group">
                                <div class="nav-heading">{{ $t('shared.footer.guides') }}</div>
                                <li class="list-group-item"><router-link to="/sizingguide">{{
                                    $t('sizing-guide.heading') }}</router-link></li>
                                <li class="list-group-item"><router-link to="/colorguide">{{ $t('color-guide.heading')
                                }}</router-link></li>
                                <li class="list-group-item"><router-link to="/garmentcare">{{ $t('care-guide.heading')
                                }}</router-link></li>
                                <li class="list-group-item"><router-link to="/custommade">{{ $t('custom-made.heading')
                                }}</router-link>
                                </li>
                            </ul>
                        </div>

                        <div class="col-6 col-sm-3">
                            <ul class="list-group">
                                <div class="nav-heading">{{ $t('shared.footer.terms') }}</div>
                                <li class="list-group-item"><router-link to="/deliveryreturns">{{
                                    $t('delivery-returns.heading') }}</router-link></li>
                                <li class="list-group-item"><router-link to="/termsconditions">{{
                                    $t('terms.heading') }}</router-link></li>
                                <li class="list-group-item"><router-link to="/privacypolicy">{{
                                    $t('privacy-policy.heading') }}</router-link>
                                </li>
                            </ul>
                        </div>

                        <div class="col-6 col-sm-3">
                            <ul class="list-group">
                                <div class="nav-heading mt-5 mt-sm-0">{{ $t('shared.footer.about-us') }}</div>
                                <li class="list-group-item"><router-link to="/contact">{{ $t('contact.heading')
                                }}</router-link></li>
                                <li class="list-group-item"><router-link to="/about">{{ $t('about.heading')
                                }}</router-link></li>
                                <!-- <li class="list-group-item"><router-link to="/team">{{ $t('team.heading')
                                }}</router-link></li> -->
                            </ul>
                        </div>

                        <div class="col-6 col-sm-3">
                            <ul class="list-group">
                                <div class="nav-heading mt-5 mt-sm-0">{{ $t('shared.footer.social-media') }}</div>
                                <li class="list-group-item"><i class="fa-brands fa-instagram fa-xl pe-1"></i><a
                                       href="https://www.instagram.com/lioi.ee/"
                                       target="_blank"
                                       rel="noopener noreferrer">Instagram</a></li>
                                <li class="list-group-item"><i class="fa-brands fa-square-facebook fa-xl pe-1"></i><a
                                       href="https://www.facebook.com/lioi.ee/"
                                       target="_blank"
                                       rel="noopener noreferrer">Facebook</a></li>
                            </ul>
                        </div>

                    </div>


                    <div class="row">
                        <div class="col">
                            <hr class="pb-2">
                        </div>
                    </div>
                    <div class="row justify-content-end">
                        <div class="col-auto">
                            <p style="font-size: 0.75rem;">2025 © Lioi OÜ.</p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    </div>
</template>

<script setup lang="ts">
</script>

<style scoped lang="scss">
@import "@/scss/abstracts/variables.scss";
@import "@/scss/abstracts/customBootstrap.scss";

.footer-wrapper {
    padding: {
        top: 4rem;
        bottom: 2rem;
    }

    background-color: $tertiary;
}

li {
    font-size: 0.75rem;
    padding-bottom: 0rem;
}
</style>