<template>
    <div class="section-background page-pt">
        <div class="container-fluid">
            <div class="row align-items-end">

                <div class="col margin-bottom">
                    <h1 class="display-3 mt-5 mt-md-3 mt-lg-0 mb-3 text-secondary"
                        v-html="$t('home.hero.heading')"></h1>
                    <!-- <p class="subheading mb-4 text-secondary">
                        {{ $t('home.hero.body') }}
                    </p> -->
                    <router-link class="btn btn-primary"
                                 to="/shop"
                                 role="button">{{ $t('home.hero.button') }}</router-link>

                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">

</script>

<style scoped lang="scss">
.section-background {
    background: {
        image: url("@/assets/hero-1.jpg");
        size: cover;
        position: center;
    }
}

.margin-bottom {
    margin-bottom: 10rem;

    // `md` applies to small devices (landscape phones, less than 768px)
    @media (max-width: 767.98px) {
        margin-bottom: 5rem;
    }

    // `sm` applies to x-small devices (portrait phones, less than 576px)
    @media (max-width: 575.98px) {
        margin-bottom: 3rem;
    }
}

.row {
    @media (min-width: 1399.98px) {
        height: 60vh;
    }

    // `xxl` applies to x-large devices (large desktops, less than 1400px)
    @media (max-width: 1399.98px) {
        height: 60vh;
    }

    // `xl` applies to large devices (desktops, less than 1200px)
    @media (max-width: 1199.98px) {
        height: 60vh;
    }

    // `lg` applies to medium devices (tablets, less than 992px)
    @media (max-width: 991.98px) {
        height: 60vh;
    }

    // `md` applies to small devices (landscape phones, less than 768px)
    @media (max-width: 767.98px) {
        height: 50vh;
    }

    // `sm` applies to x-small devices (portrait phones, less than 576px)
    @media (max-width: 575.98px) {
        height: 50vh;
        margin-right: 9rem;
    }
}

.subheading {
    font-size: 0.9rem;
}
</style>
